<template>
  <v-container fluid class="pa-0">
    <v-container fluid class="content-container">
      <v-row class="justify-center">
        <v-col
            class="d-flex flex-column align-center col-12 col-sm-12 col-md-6"
        >
          <div class="left-column d-flex flex-column">
            <div class="header">
              <h1
                  class="header__title text-verification"
                  v-html="$t('signUpPage.textVerification.title')"
              />
            </div>
            <SignupText />
          </div>
        </v-col>
        <v-col class="col-12 col-sm-12 col-md-6">
          <div class="right-column">
            <div class="feature-image">
              <v-img
                  src="../assets/verify_text_feature.jpeg"
                  alt="Feature Image"
                  width="100%"
                  height="100%"
              />
            </div>
            <div class="text-center">
              <router-link
                  class="learn-more-link"
                  :to="{ path: 'ways' }"
              >
                {{ $t("signUpPage.textVerification.learnMore") }}
              </router-link>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" max-width="970">
        <v-card color="tertiaryBackground pa-5 pa-sm-12">
          <div class="confirmation-container">
            <v-card-title class="confirmation-title d-flex flex-column align-center">
              <div class="confirmation-image mb-4">
                <img
                    src="../assets/send.svg"
                    alt="check phone image"
                />
              </div>
              <h3>
                {{ $t("signUpPage.textVerification.confirmation.title") }}
              </h3>
            </v-card-title>
            <v-card-subtitle class="confirmation-subtitle pa-0 mb-3">
              <h4>{{ $t("signUpPage.textVerification.confirmation.subtitle", { number: phoneNumber }) }}</h4>
            </v-card-subtitle>
            <v-card-text>
              <TextField
                  v-model="phone"
                  type="text"
                  :placeholder="$t('homePage.enterPhone')"
                  class="mb-5"
              />
              <DefaultButton
                  class="confirm-btn"
                  color="text"
                  :disabled="!confirmationCode"
              >
                {{ $t("signUpPage.textVerification.confirmation.confirm") }}
              </DefaultButton>
            </v-card-text>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
    <HomeArm/>
  </v-container>
</template>

<script>

// import RoundedTextField from "@/components/inputs/RoundedTextField.vue";
import DefaultButton from "@/components/buttons/DefaultButton.vue";
import HomeArm from "@/components/HomeArm.vue";
import TextField from '@/components/TextField.vue';
import SignupText from '@/components/SignupText.vue';

import { validatePhone } from '@/lib/';

export default {
  name: "TextVerification",
  components: {
    TextField,
    // RoundedTextField,
    DefaultButton,
    SignupText,
    HomeArm
  },
  data() {
    return {
      phone: "",
      isConfirmed: false,
      phoneNumber: "",
      confirmationCode: "",
      dialog: false,
      rules: {
        required: value => !!value || this.$t('login.required'),
        phone: value => validatePhone(value) || this.$t('login.badPhone')
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.coupon-banner {
  background-color: var(--v-text-base);
  text-align: center;
}

.coupon-banner h5 {
  color: var(--v-textOnSecondary-base);
  font-size: 34px;
  font-weight: 600;
  line-height: 150%; /* 36px */
}

.content-container {
  padding: 36px 100px;
}

.left-column {
  max-width: 100%;
  width: 440px;
  gap: 31px;
}

.input-container {
  width: 100%;
}

.right-column {
  max-width: 100%;
  width: 619px;
}

.feature-image {
  width: 100%;
  aspect-ratio: 1.77;
  margin-bottom: 69px;
}

.header__title {
  color: var(--v-primaryAction-base);
  font-family: "BebasNeue";
  font-size: 64px;
  font-weight: 600;
  line-height: 120%;
}

.learn-more-link {
  color: var(--v-secondary-base);
  font-size: 36px;
  font-weight: 600;
  line-height: 150%; /* 54px */
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.learn-more-link:hover {
  color: var(--v-text-base);
}

.confirmation-container {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

.confirmation-title h3 {
  color: var(--v-secondaryText-base);
  font-size: 36px;
  font-weight: 600;
  line-height: 150%; /* 54px */
  word-break: normal;
}

.confirmation-subtitle h4 {
  color: var(--v-secondaryText-base);
  font-size: 24px;
  font-weight: 400;
  line-height: 150%; /* 36px */
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .coupon-banner h5 {
    font-size: 24px;
  }

  .header {
    text-align: center;
  }

  .header__title {
    font-size: 40px;
  }

  .left-column {
    width: 100%;
    margin-bottom: 30px;
  }

  .right-column {
    width: 100%;
  }

  .buttons {
    gap: 24px;
  }

  .input-container {
    width: calc(50% - 12px);
    min-width: 280px;
  }

  .feature-image {
    margin-bottom: 28px;
  }

  .learn-more-link {
    font-size: 24px;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .coupon-banner h5 {
    font-size: 16px;
  }

  .content-container {
    padding: 60px 30px;
  }

  .buttons {
    gap: 16px;
  }
}
</style>
